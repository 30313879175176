/*
 * Around | Multipurpose Bootstrap HTML Template
 * Copyright 2022 Createx Studio
 * Theme styles
*/

// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'utilities';

// Bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities/api';

// Layout & componentss
@import 'reboot';
@import 'components';

// Dark mode
@import 'dark-mode';

// User custom styles
@import 'user';



.navbar-brand img {
    height: 60px;
}


.fixed-top-gga {
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    z-index: 1030;
}

.topnav {
    list-style: none;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px;
}

@media only screen and (max-width: 600px) {
    .topnav {
        display: none;
    }
}

.topnav li {
    padding: 10px;
}

.topnav li a {
    text-decoration: none;
}

#topnavcontainer {
    height: 26px;
    overflow-y: hidden;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

#topnavcontainer.hide-container {
    max-height: 0;
}

.navbar-collapse {
    border: none;
}

.mt-banner {
    margin-top: 150px;
}

.card-body-icon {
    width: 30px;
    margin-bottom: 15px;
}

#flywithhelpcontainer {
    display: none;
}

#flywithhelpcontainer.active {
    display: block;
}


.flywith-button {
    display: flex;
    position: fixed;
    z-index: 1032;
    right: -80px;
    top: 50%;
    margin-top: -20px;
    padding: 10px 25px 30px 5px;
    text-decoration: none;

    font-size: 16px;
    background: $primary;
    color: #FFF;


    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

a:hover.flywith-button {
    color: #FFF;
    right: -70px;
}

.flywith-section {
    right: 0;
    vertical-align: middle;
    position: fixed;

    z-index: 1032;
    top: 50%;
    margin-right: 70px;
    margin-top: -80px;
    width: 500px;
    border: none;
    padding: 20px;
    max-height: calc(100vh - 250px);
    overflow-y: auto;
}

@media only screen and (max-width: 600px) {
    .flywith-section {
        bottom: 50px;
        border: none;
        padding: 20px;
        top: unset;
        bottom: 80px;
        right: unset;
        margin: 15px 15px 0;
        width: auto;
        height: calc(100vh - 50px);
        overflow-y: auto;
    }

    .flywith-button {
        right: -80px;
        top: unset;
        right: 0px;
        bottom: 20px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    .flywidth-button-text {
        display: none;
    }
}

.flywith-body a {
    display: block;
    margin: 10px 0px;
    padding: 3px;
    text-decoration: none;
    font-weight: 600;
    background-color: #fff;
    line-height: 1.5;
    font-size: 1rem;
    padding: 15px;
    color: var(--ar-body-color);

}

.flywith-body a i {
    font-size: 2rem;
}

.flayout-section a:hover {
    background-color: var(--ar-body-color);
    color: #fff;

}

.footer-top {
    background-color: rgb(111, 111, 111);
    color: #fff;
}

.footer-nav-item .nav-link {
    font-size: 16px;
    color: #fff !important;
}

.bigspace-top {
    margin-top: 150px;
}

.bigspace-bottom {
    margin-bottom: 20 0px;
}


/*
*************************************************************
** Modal Window                                             *
*************************************************************
*/

.iamodalcontainer {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);
    top: 0;
    left: 0;
    z-index: 5000
}

.iamodalcontainer .iamodal {
    background: white;
    width: 100%;
    max-width: 1089px;
    height: 416px;
    margin: auto;
    margin-top: 200px;
    position: relative;
    -webkit-box-shadow: 0 0 22px 0 rgba(0, 0, 0, .69);
    -moz-box-shadow: 0 0 22px 0 rgba(0, 0, 0, .69);
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, .69)
}

.iamodalcontainer .iamodal .iamodalclosecontainer {
    text-align: right
}

.iamodalcontainer .iamodal .iamodalclosecontainer a {
    display: block;
    padding: 30px;
    color: #707070
}

.iamodalcontainer .iamodal .textcontainer .iamodalcontent {
    text-align: center;
    padding: 0 30px;
}


.iamodalcontainer .iamodal .iamodalform {
    position: absolute;
    bottom: 0;
    width: 100%
}

.iamodalcontainer .iamodal .iamodalform .zipCodeTextbox {
    margin: auto;
    width: 100%;
    max-width: 500px;
    display: block;
    text-align: center;
    margin-bottom: 60px;
    font-size: 40px;
    color: black
}

.iamodalcontainer .iamodal .iamodalform .zipCodeTextbox:focus {
    border-bottom: 1px solid #707070;
    -webkit-box-shadow: 0 1px 0 0 #707070;
    box-shadow: 0 1px 0 0 #707070
}

.iamodalcontainer .iamodal .iamodalform .zipCodeSubmitButton {
    width: 100%;
    bottom: 0;
    background: #d22630;
    border: none;
    height: 64px;
    font-size: 22px;
    color: white
}

@media screen and (max-height:700px) {
    .iamodalcontainer .iamodal {
        margin-top: 50px
    }
}


/*
*************************************************************
** Internet CONTENT                                         *
*************************************************************
*/

.mouse {
    background: #2c333e linear-gradient(transparent 0%, transparent 50%, #121519 50%, #121519 100%);
    position: relative;
    width: 38px;
    height: 65px;
    margin: 0 auto;
    border-radius: 6rem;
    background-size: 100% 200%;
    animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}

.mouse:before,
.mouse:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.mouse:before {
    width: 34px;
    height: 61px;
    background-color: white;
    border-radius: 6rem;
}

.mouse:after {
    background-color: #121519;
    width: .375rem;
    height: .375rem;
    border-radius: 100%;
    animation: trackBallSlide 5s linear infinite;
}

@keyframes trackBallSlide {
    0% {
        opacity: 1;
        transform: scale(1) translateY(-20px);
    }

    6% {
        opacity: 1;
        transform: scale(0.9) translateY(5px);
    }

    14% {
        opacity: 0;
        transform: scale(0.4) translateY(40px);
    }

    15%,
    19% {
        opacity: 0;
        transform: scale(0.4) translateY(-20px);
    }

    28%,
    29.99% {
        opacity: 1;
        transform: scale(1) translateY(-20px);
    }

    30% {
        opacity: 1;
        transform: scale(1) translateY(-20px);
    }

    36% {
        opacity: 1;
        transform: scale(0.9) translateY(5px);
    }

    44% {
        opacity: 0;
        transform: scale(0.4) translateY(40px);
    }

    45%,
    49% {
        opacity: 0;
        transform: scale(0.4) translateY(-20px);
    }

    58%,
    59.99% {
        opacity: 1;
        transform: scale(1) translateY(-20px);
    }

    60% {
        opacity: 1;
        transform: scale(1) translateY(-20px);
    }

    66% {
        opacity: 1;
        transform: scale(0.9) translateY(5px);
    }

    74% {
        opacity: 0;
        transform: scale(0.4) translateY(40px);
    }

    75%,
    79% {
        opacity: 0;
        transform: scale(0.4) translateY(-20px);
    }

    88%,
    100% {
        opacity: 1;
        transform: scale(1) translateY(-20px);
    }
}

@keyframes nudgeMouse {

    0%,
    30%,
    60%,
    90% {
        transform: translateY(0);
    }

    20%,
    50%,
    80% {
        transform: translateY(8px);
    }
}

@keyframes nudgeText {

    0%,
    30%,
    60%,
    90% {
        transform: translateY(0);
    }

    20%,
    50%,
    80% {
        transform: translateY(2px);
    }
}

#scrollmouse {
    height: 86px;
    overflow-y: hidden;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

#scrollmouse.hide-container {
    max-height: 0;
}

.page-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s .2s ease-in-out;
    transition: all .4s .2s ease-in-out;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
}

.dark-mode .page-loading {
    background-color: #121519;
}

.page-loading.active {
    opacity: 1;
    visibility: visible;
}

.page-loading-inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    opacity: 0;
}

.page-loading.active>.page-loading-inner {
    opacity: 1;
}

.page-loading-inner>span {
    display: block;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: #6f788b;
}

.dark-mode .page-loading-inner>span {
    color: #fff;
    opacity: .6;
}

.page-spinner {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: .75rem;
    vertical-align: text-bottom;
    background-color: #d7dde2;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner .75s linear infinite;
    animation: spinner .75s linear infinite;
}

.dark-mode .page-spinner {
    background-color: rgba(255, 255, 255, .25);
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}